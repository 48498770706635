export default {
  auth: {
    invalidCredentials: 'Your email or password is incorrect',
    getTokenError: 'An error occurred trying to get the user token',
    createAccountError: 'An error occurred trying to create the account',
    deleteAccountError: 'An error occurred trying to delete the account',
    updateProfileError: 'An error occurred trying to update the profile',
    sendEmailVerificationError: 'An error occurred trying to send the email',
    verifyEmailError: 'An error occurred trying to verify the email',
    forgotPasswordError: 'An error occurred trying to reset the password',
    changePasswordError: 'An error occurred trying to change the password',
    resetPasswordError: 'An error occurred trying to reset the password',
  },
  freemium: {
    title: 'Join the INQUARI pilot to gain access to:',
    description1: 'Unlimited contract creation',
    description2: 'Personalized onboarding for you, your team and collaborators',
    description3: 'Additional three months of free access when platform officially launches',
    action: 'JOIN PILOT',
  },
  common: {
    help: 'HELP',
    success: 'SUCCESS',
    createAccount: 'CREATE ACCOUNT',
    create: 'CREATE',
    login: 'LOG IN',
    signOut: 'SIGN OUT',
    forgotPassword: 'FORGOT YOUR PASSWORD?',
    send: 'SEND',
    done: 'DONE',
    ok: 'OK',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    oldPassword: 'Old Password',
    createYourPassword: 'Create Your Password',
    companyName: 'Company Name',
    continue: 'CONTINUE',
    requiredField: 'This is a required field',
    enterValidEmail: 'Enter a valid email',
    minPassword: '8 character min.',
    passwordMustMatch: 'Password must match',
    ownerEmail: 'This email must be different than yours',
    close: 'CLOSE',
    error: 'ERROR',
    reset: 'RESET',
    confirm: 'CONFIRM',
    submit: 'SUBMIT',
    cancel: 'CANCEL',
    assigne: 'ASSIGN',
    apply: 'APPLY',
    no: 'NO',
    yes: 'YES',
    changePassword: 'CHANGE PASSWORD',
    saveChanges: 'SAVE CHANGES',
    back: 'Back',
    backToPortfolio: 'Back To Portfolio',
    edit: 'Edit',
    viewContract: 'VIEW CONTRACT',
    viewPayment: 'VIEW PAYMENT INSTRUCTIONS',
    goHome: 'GO TO PORTFOLIO',
    delete: 'Delete',
    mustBeNumber: 'This must be a positive number',
    agree: 'I AGREE',
    dontAgree: 'I DISAGREE',
    agreeConfirmationTitle: 'Ready to Confirm?',
    areYouSure: 'Are you sure?',
    freemiumErrorTitle: 'FREEMIUM ERROR',
    freemiumErrorMessage: 'Sorry, you reach the limit of contracts for your free account.',
    notNow: 'NOT NOW',
    goPremium: 'GO PREMIUM',
    new: 'New',
    forbidden: 'You dont have enough permission to perform that operation',
    notifications: 'NOTIFICATIONS',
    today: 'Today',
    yesterday: 'Yesterday',
    deleteContractTitle: 'YOU ARE ABOUT TO DELETE THIS CONTRACT',
    deleteContractDes1: 'This action can’t be undone',
    deleteContractDes2: 'Are you sure you wan’t to do this?',

    deleteTaskTitle: 'YOU ARE ABOUT TO DELETE THIS TASK',
    deleteTaskDes1: 'This action can’t be undone',
    deleteTaskDes2: 'Are you sure you wan’t to do this?',

    noChangeMyMind: 'NO, I CHANGED MY MIND',
    yesDelete: 'YES, DELETE IT',
    deliverySchedule: 'Delivery Schedule',
    resetOTP: 'SET UP AUTHENTICATOR',
    menu: {
      deliverySchedule: 'Delivery Schedule',
      contractSummary: 'Contract Summary',
      contractTerms: 'Contract Terms',
      paymentInstructions: 'Payment Instructions',
      documents: 'documents',
      workflow: 'Workflow',
    },
    menuShort: {
      deliverySchedule: 'Schedule',
      contractSummary: 'Summary',
      contractTerms: 'Terms',
      paymentInstructions: 'Payment',
      documents: 'documents',
      workflow: 'Workflow',
      more: 'More',
    },
    upload: 'Upload',
    rfp: 'Required',
    document: 'Document',
    default: 'By Default',
    allBuyers: 'All buyers',
    allSellers: 'All sellers',
    allProducts: 'All products',
    deliveryDateRange: 'Delivery Date Range',
    all: 'All',
    contracts: 'Contracts',
    deliveries: 'Deliveries',
    clientSupport: 'Client Support',
    havenDefinedDeilvery: 'YOU HAVEN’T DEFINED YOUR DELIVERY SCHEDULE',
    needDeliveryCadence: 'You need to set a delivery cadence before you can manage your workflow',
    setDeliverySchedule: 'SET DELIVERY SCHEDULE',
    privacy: 'Privacy Policy',
    terms: 'Terms and Conditions',
    byPushingCreate: "I have read and agree with Inquari's ",
    andOur: 'and',
    ofInquari: '',
  },
  onboarding: {
    title1: 'WELCOME',
    description1:
      'Please download the Google Authenticator App in order to create and verify your account.',
  },
  createAccount: {
    description: 'Please fill-out the following information.',
    description2:
      'After creating your account, you will receive a link in your inbox that will verify your email address.',
  },
  emailVerification: {
    successMessage: 'An email has been successfully sent to {{emaill}} for your verification.',
    successVerification: 'Thank you! Your email has been verified.',
    title: 'EMAIL VERIFICATION',
    subtitle: 'YOUR ACCOUNT HAS BEEN SUCCESSFULLY CREATED',
    description: "We've sent you an email to {{email}} with a link to verify your account.",
    sendNewEmail: 'RESEND EMAIL',
    refresh: "I'VE VERIFIED MY EMAIL",
  },
  forgotPassword: {
    title: 'FORGOT YOUR PASSWORD?',
    subtitle: 'RESET YOUR PASSWORD',
    description: "Enter your email & we'll send you a link so you can reset your password",
    sent: {
      title: 'SENT!',
      description1: 'A link to reset your password has been sent.',
      description2: 'For any questions or to report an issue, please email help@inquari.com',
    },
  },
  resetPassword: {
    description: 'Please enter your new password.',
    success: 'Password has been updated.',
    savePassword: 'SAVE NEW PASSWORD',
  },
  validateToken: {
    title: 'AUTHENTICATION',
    description: 'Please confirm your account by typing the code given by google authenticator',
    verifyCode: 'VERIFY CODE',
    resetDescription:
      "Click here if you don't have access to your Google Authenticator and would like to reset it via email",
    invalid: 'Token invalid',
    resetSent: 'We sent an email to {{email}} with a link to reset Google Authenticator.',
  },
  setupGoogleAutheticator: {
    title: 'SET UP AUTHENTICATOR',
    'You need to download the': 'You need to download the',
    'Authenticator App': 'Authenticator App',
    'and paste this KEY there': 'and paste this KEY there',
    'to get your access code': 'to get your access code',
    'or scan the QR Code': 'or scan the QR Code',
    'Please verify your identity using the code generated by the':
      'Please verify your identity using the code generated by the',
    'Google Authenticator App': 'Google Authenticator App',
    copyKeyMobile: 'COPY KEY TO CLIPBOARD',
    copyKeyDesktop: 'OR COPY KEY',
    modalTitle: 'GETTING AUTHETICATED',
    modalInstructions1:
      'In order to maximize identity security on the INQUARI platform, we use a 2 Factor Authentication system leveraging the Google Authenticator app.',
    modalInstructions2:
      'Once you download and register in that app tap on the Add (+) button and copy the key that we gave you.',
    modalInstructions3:
      'Google Authenticator will show you a numeric code that you can type here so we can confirm your account.',
    modalInstructions4:
      "You'll need to do enter the Google Authenticator code every time we need to confirm your account.",
  },
  home: {
    empty: {
      title: '',
      description: '',
      step1Title: '1. SET UP YOUR CONTRACT',
      step1Description:
        'We know each contract has a lot of information. You can set up your contract over one or several sessions or assign someone on your team to help you. ',
      step2Title: '',
      step2Description: 'NEED MORE HELP? ',
      step2Link: 'WATCH THIS VIDEO',
      endStepTitle: 'YOU HAVE NO CONTRACTS',
      endStepDescription: 'ADD YOUR FIRST CONTRACT',
      welcome: 'WELCOME',
      welcomeDescription: 'Set up your deal in easy steps',
    },
    filters: 'FILTERS',
    sortBy: 'Sort by',
    // - Delivery status (Behind - On Time - Ahead) - Delivery status (Ahead - On Time - Behind) - Total contract volume - Total nominated volume - Total delivered volume
    deliveryStatusBehind: 'Delivery status (Behind - On Time - Ahead)',
    deliveryStatusAhead: 'Delivery status (Ahead - On Time - Behind)',
    totalContract: 'Total contract volume',
    totalNominated: 'Total nominated volume',
    totalVolume: 'Total delivered volume',
    received: 'Delivered',
    nominated: 'Nominated',
    pending: 'Pending',
  },
  filters: {
    title: 'FILTERS',
    offTakeStatus: 'OFF-TAKE STATUS',
    currentlyNominated: 'Currently nominated',
    deliveryDateRange: 'DELIVERY DATE RANGE',
    buyers: 'BUYERS',
    sellers: 'SELLERS',
    products: 'PRODUCTS',
  },
  paymentInstructions: {
    descriptionPayment: `Securely communicate your account information to (insert buyer's name) via this form.`,
    title: 'Payment Instructions',
    descriptionWithoutSeller:
      'To enable payment instruction section, you need to include seller information on the contract general information',
    description:
      "This information will only be visible to the Buyer and, if you choose, your Financier. You'll be able to edit this information whenever you wish - however, after either a Buyer or Financier has confirmed - any changes would require them to re-confirm their agreement.",
    descriptionEditing:
      "This information will only be visible to {{buyerName}} and, if you choose, your Financier. You'll be able to edit this information whenever you wish - however, after either {{buyerName}} or Financier has confirmed - any changes would require them to re-confirm their agreement.",
    descriptionEditingWithFinancier:
      "This information will only be visible to {{buyerName}} and {{financierName}}. You'll be able to edit this information whenever you wish - however, after either {{buyerName}} or {{financierName}} has confirmed - any changes would require them to re-confirm their agreement.",
    title2: 'COLLATERAL ASSIGNMENT',
    description2: `If this contract has been pledged as collateral, keep everyone in the loop by including the financier's information below. This will invite the financier to view and confirm payment instructions.`,
    contractReference: 'Contract Reference #',
    buyerEmail: 'Buyer’s Email',
    buyerName: 'Buyer’s Name',
    buyerFirstName: 'First Name',
    buyerLastName: 'Last Name',
    accountHolderName: 'Name of Account',
    bankName: 'Bank Name, Country, City, State',
    routingNumber: 'ABA or SWIFT CODE',
    accountNumber: 'Account Number',
    financier: 'Financier',
    legalEmail: 'Legal Representative Email',
    legalName: 'Legal Representative Name',
    legalFirstName: 'First Name',
    legalLastName: 'Last Name',
    beneficiaryTitle: 'ACCOUNT BENEFICIARY INFORMATION',
    beneficiaryDescription:
      'Please provide the information requested below exactly as it appears in the records of the financial institution at which the account is held.',
    beneficiaryName: 'Account Beneficiary Name',
    beneficiaryAddress: 'Physical Address of Beneficiary',
    beneficiaryComments: 'Other Comments',
    confirmation: {
      title: 'CONFIRMATION',
      collaborationTitle: 'COLLABORATION REQUEST',
      acknowledgmentTitle: 'ACKNOWLEDGMENT',
      titleBox: 'PAYMENT INSTRUCTIONS',
      description: 'Before any changes can be accepted terms should be confirmed by all parties.',
      collaborationSubitle: 'PAYMENT INSTRUCTION OF',
      collaborationContract: 'CONTRACT #{{contractReference}}',
      to: 'TO',
      agreements1: ' I confirm that this contract has not been pledged as collateral',
      agreements2:
        'I would like to inform you that this sales contract has been pledged as collateral to {{bankName}} and that we are also requesting their legal representative’s acknowledgement of the payment instructions.',
      updated: 'Payment instructions updated.',
      created: 'Payment instructions created.',
    },
    denied: 'Denied',
    confirmed: 'Confirmed',
    pending: 'Pending approval',
    approval: 'Approval',
    accepted: 'Accepted',
    actionRequired: 'Action Required',
    agree: 'I AGREE',
    dontAgree: 'I DISAGREE',
    agreeConfirmationTitle: 'Ready to Confirm?',
    agreeConfirmationDescription:
      'By confirming you are agreeing that all payments for this contract will be made to the referenced account unless these instructions are updated and confirmed.',
    agreeSuccess: 'Your update has been sent successfully',
    comment: 'COMMENT',
    commentDescription: 'Please let {{sellerName}} know why you disagree.',
    deniedCommentSuccess: 'Thank you for providing your feedback.',
    lastEdited: 'LAST EDITED: ',
    requestSent: 'The request has been successfully sent',
  },
  profile: {
    title: 'PROFILE',
    successDescription: 'Profile successfully updated.',
    viewProfile: 'VIEW PROFILE',
  },
  contracts: {
    sumary: 'CONTRACT SUMMARY',
    portfolio: 'PORTFOLIO',
    nominated: 'Nominated',
    behind: 'Behind',
    undefined: 'No delivery schedule',
    addOffTakeSchedule: 'Add delivery schedule',
    undefinedDeliver: 'No delivery schedule',
    active: 'Active',
    onTime: 'On-Time',
    ahead: 'Ahead',
    completed: 'Completed',
    contractName: 'Contract Name',
    contractReference: 'Contract Reference #',
    contractCounterpartReference: 'Counterpart Reference #',

    totalVolume: 'Total Volume',
    totalVol: 'TOTAL VOL',
    sugarSpec: 'Sugar Spec',
    buyerEmail: 'Buyer Email',
    buyerEmailLabel:
      "Buyer's Email (Optional - Include here if you like to initiate two-team mode)",
    counterpartPlaceHolder: 'Include here if you would like to initiate two-team mode',
    sellerEmail: "Seller's Email (Optional - Include here if you like to initiate two-team mode)",
    packagingType: 'Packaging Type',
    createContract: 'CREATE CONTRACT',
    proposeChanges: 'DRAFT NEW PROPOSAL',
    manageDeliverySchedule: 'MANAGE DELIVERY SCHEDULE',
    delete: 'DELETE CONTRACT',
    print: 'PRINT',
    archive: 'ARCHIVE CONTRACT',
    seeContract: 'SEE CONTRACT',
    imcCmmercialExecutive: ' I’m creating the contract on behalf of the commercial executive',
    addCounterpart: 'I would like to invite my commercial counterpart',
    deny: {
      title: 'Add a comment to {{title}}',
      description: 'Your comment will be reviewed by your counterpart',
      successMessage: 'Your comment has been sent successfully',
      deniedCommentSuccess: 'Thank you for providing your feedback.',
    },
    seeComment: {
      title: '{{name}} has a comment',
    },
    reviewChanges: 'Please review changes proposed by {{counterpart}} team',
    waitingReview: 'Waiting on approval by counterpart',
    waiting:
      'This information is under review by other parties. Until all parties have confirmed the new changes you won’t be able to proposse new changes.',
    changesConfirmed: 'Changes confirmed by all parties',
    deniedTitle: 'Proposed change was denied.',
    deniedMessage: 'Please wait until {{counterpart}} check the information',
    completeContractTerms: 'COMPLETE CONTRACT TERMS',
    manageWorkflow: 'MANAGE WORKFLOW',
    modifyTerms: 'EDIT GENERAL INFORMATION',
    generalInformation: {
      title: 'CREATE A CONTRACT',
      title2: 'TERMS',
      title3: 'KEY TERMS',
      description: 'Provide the following key terms in order to create your contract.',
      descriptionEdit: 'Complete this basic information about your contract',
      description2: '',
      description3: 'Let’s start with the basic terms. You can include more later.',
      iam: 'I’M THE',
      seller: 'SELLER',
      buyer: 'BUYER',
      sellSide: 'SELL-SIDE',
      buySide: 'BUY-SIDE',
      noSeller: 'NO SELLER',
      noBuyer: 'NO BUYER',
      minVolume:
        'The are off-take schedules that sum {{volumeScheduled}} MT, please remove them before do any changes',
      sellerRepresentativeFirstName: 'SELLER’S FIRST NAME',
      sellerRepresentativeLastName: 'SELLER’S LAST NAME',
      sellerRepresentativeEmail: 'SELLER’S EMAIL',
      sellerCompany: 'Seller’s Company',
      buyerRepresentativeFirstName: 'BUYER’S FIRST NAME',
      buyerRepresentativeLastName: 'BUYER’S LAST NAME',
      buyerRepresentativeEmail: 'BUYER’S EMAIL',
      buyerCompany: 'Buyer’s Company',
      counterpartHelpMessage:
        'This feature allows you to invite your commercial counterpart so that both the buy and sell side teams can manage their own workflows related to the contract as well as easily collaborate on tasks - like nomination, delivery and a working list of documents required for payment - that concern both teams.  By inviting your counterpart, contract terms, and off-take schedules, nomination and delivery tasks would require confirmation from both teams.  You can invite your counterpart now or later by navigating to the contract invitations.',
      commercialExecutiveHelpMessage:
        'Considered to be the person who carries out the contract negotiation, and one of the contract owners',
      success: 'You created your contract',
      whatNext: {
        title: 'What’s next?',
        item1: 'Create Contract',
        item2: 'Include the rest of your terms',
        item3: 'Set your Off-Take Schedule',
        item4: 'Invite collaborators',
        item5: 'Request the payment instructions to your counterpart',
      },
      goSummary: 'GO TO THE  CONTRACT SUMMARY',
      keepAddingTerms: 'KEEP ADDING TERMS',
    },
    contractSpecs: {
      title: 'CONTRACT SPECS',
      description: '',
    },
    paymentInstructions: {
      title: 'PAYMENT INSTRUCTIONS',
      buyerInformation: 'BUYERS INFORMATION',
      sellerInformation: 'SELLER INFORMATION',
      sendRequestForPayments: 'SEND REQUEST FOR PAYMENT INSTRUCTIONS',
      addSellerInfo: 'ADD SELLER INFORMATION',
    },
    offTakeSchedule: {
      title: 'WORKFLOW',
      title2: 'Added Off-Takes',
      description:
        'Customize your contract workflow & monitoring with target volumes and date ranges.',
      description2: 'All changes need to be approved by your counterpart.',
      volume: 'Volume',
      dateRange: 'Date Range',
      add: 'ADD',
      edit: 'EDIT',
      sentSuccess: 'The off take schedules terms has been successfully sent',
      volumeExceeded: 'Exceeds contract total volume ({{totalVolume}}) MT',
      minVolume: 'The volume have to be more than 0',
    },
    addOtherTerms: {
      addTerms: 'Added terms',
      title: 'OTHER TERMS',
      description: 'Add all of your contract terms here individually',
      add: 'ADD TERM',
      edit: 'EDIT TERM',
      label: 'Label',
      type: 'Type',
      descriptionLabel: 'Description',
      sentSuccess: 'The contract terms has been successfully sent',
    },
    addCollaborators: {
      title: 'INVITE',
      title2: 'INVITATIONS',
      description:
        'Invite your team, commercial counterpart and external contacts who will be involved with this contract.  Choose how much information to share with each person by assigning their role for this contract.',
      add: 'ADD',
      edit: 'EDIT',
      send: 'SEND INVITATIONS',
      listTitle: 'YOUR COLLABORATORS',
      sentSuccess: 'The invitations has been successfully sent',
      seeRolePermissions: 'ROLE PERMISSIONS',
      roles: {
        commercialOwner: 'Contract Owner',
        contractOwner: 'Contract Owner',
        operationsManager: 'Operations Manager',
        contractAgent: 'Contract Agent',
        collaborationAgent: 'Collaboration Agent',
        taskAgent: 'Task Agent',
        exportAgent: 'Export Agent',
        financier: 'Financier I',
        financier2: 'Financier II',
        taskCollaborator: 'Task Collaborator',
        commercialExecutive: 'Commercial Executive',
      },
      rolePermissions: {
        title: 'ROLE PERMISSIONS',
        description: '',
        commercialOwnerDescription: '',
        contractOwnerDescription: '',
        operationsManagerDescription:
          'Anyone responsible for the movement of the cargo for the trade either in the buyer or seller’s team',
        contractAgentDescription:
          'Anyone who takes a role in the operational flow of a contract that is not necessarily part of the buyer or seller’s team or an export agency. This could be an agent at a transportation company',
        collaborationAgentDescription: '',
        taskAgentDescription: '',
        exportAgentDescription: 'Anyone taking an active role in the exporting process for a trade',
        financierDescription:
          'Has greater visibility and access to more information than a financier II. Users under this category have access to the status of contract, terms of contract, payment instructions, workflow schedule, and ability to see collaborating users',
        financier2Description:
          'This role has more limited visibility on information pertaining to the contracts. Compared to a financier I, they can only see payment instructions and any collaborating users for that contract',
        taskCollaboratorDescription: '',
        commercialExecutiveDescription:
          'Considered to be the person who carries out the contract negotiation, and one of the contract owners',
      },
    },
    details: {
      invite: {
        contractTeam: 'CONTRACT TEAM',
      },
      contractTerms: 'CONTRACT TERMS',
      contractTermsAction: '+ADD OR EDIT TERMS',
      contractTeam: 'CONTRACT TEAM',
      terms: 'TERMS',
      obligations: 'OBLIGATIONS',
      options: 'OPTIONS',
      workflow: {
        add: 'ADD',
        edit: 'EDIT',
        title: 'WORKFLOW',
        description: '',
        subtitle: 'Set up your deal in easy steps',
        first_step_title: '1.Set up your first task',
        first_step_description:
          'You’ll need to select the type between a Nomination, delivery and general tasks.',
        offTakeSchedule: {
          title: 'OFF-TAKE SCHEDULE',
        },
        offTakeScheduleDetail: {
          title: 'WORKFLOW DETAIL',
        },
        empty: {
          endStepTitle: 'YOU HAVE NO TASKS',
          endStepDescription: 'ADD YOUR FIRST TASK',
        },
        listTitle: 'YOUR DELIVERY SCHEDULE',
        taskSubtitle: 'For SCHEDULE: {{date}} / Total volume: {{volume}}MT',
        subtaskSubtitle: 'For NOMINATION: {{date}} - {{volume}}',
        general: 'GENERAL',
        delivery: 'DELIVERY',
        nomination: 'NOMINATION',
        dataPointRequired: 'DATA POINTS',
        dataPointRequiredDescription: '',
        requirements: 'Requirements',
        dataPointDescription: '',
        documentRequired: 'DOCUMENTATION',
        documentRequiredDescription: '',
        documentDescription: '',
        recipients: 'RECIPIENTS',
        generalPage: {
          title: '{{action}}GENERAL TASK',
          description: '',
          titleInput: 'Title',
          descriptionInput: 'Description',
          completionDate: 'Completion Date',
          markDone: 'MARK AS DONE',
          completingTitle: 'YOU ARE CONFIRMING',
          completingDescription: '',
          comments: 'Comments',
          done: 'Done',
          add: 'ADD TASK',
        },
        deliveryPage: {
          title: '{{action}}DELIVERY TASK',
          description: '',
          titleInput: 'Title',
          location: 'Location',
          pickupLocation: 'Pick up location',
          countryOrigin: 'Country of Origin',
          deliveryLocation: 'Delivery location',
          completionDate: 'Sailing Date',
          date: 'Date',
          tbd: 'TBD',
          markDelivered: 'MARK AS DELIVERED',
          deliveringTitle: 'YOU ARE CONFIRMING PHYSICAL DELIVERY',
          deliveringDescription: '',
          comments: 'Comments',
          sugarSpec: 'Sugar spec',
          volume: 'Volume',
          delivered: 'Delivered',
          referenceNumber: 'Reference Number',
          optional: '(Optional)',
          refNumberLabel: 'Ref #',
          add: 'ADD TASK',
        },
        nominationPage: {
          title: '{{action}}NOMINATION TASK',
          description: '',
          pickupLocation: 'Pick up location',
          countryOrigin: 'Country of Origin',
          volume: 'Volume',
          date: 'Date',
          tbd: 'TBD',
          from: 'From',
          till: 'Till',
          add: 'ADD TASK',
          timeframe: 'Timeframe',
          markNominated: 'MARK AS NOMINATED',
          nominatingTitle: 'YOU ARE ABOUT TO NOMINATE',
          nominatingDescription: '',
          nominated: 'Nominated',
          comments: 'Comments',
          eta: 'ETA',
          etd: 'ETD',
        },
        invites: {
          title: 'INVITE TO TASK',
          description: '',
          title2:
            'Not on the list? No problem, enter the email of the user you want to assign this task and click add, then click on the Assign button.',
          description2: 'This users will only have access to their assigned tasks',
        },
        sentSuccess: 'The task has been successfully sent',
        requiredForPayment: 'Required for payment',
        requiredForReview: 'Required for review',
        assignTask: 'INVITE',
        nominationStatusPending:
          'Once you upload all the requirements you will be able set this task as done',
        addComment: 'ADD COMMENT',
      },
    },
    box: {
      type: 'Type',
      contractName: 'Contract name',
      totalVolume: 'Total volume',
      contractCode: 'Contract code',
      incoTerm: 'INCO term',
      packagingType: 'Packaging type',
      sugarSpec: 'Sugar Spec',
    },
    contractStatus: 'Contract Status',
    today: 'Today',
    haventAdded: 'You haven’t added any delivery dates',
    addDeliverySchedule: '+ADD your delivery schedule',
  },
  documents: {
    documentName: 'Document name',
    uploadDate: 'Upload date',
    nominationVol: 'Nomination # / VOL',
    UploadedBy: 'Uploaded by',
    download: 'Download',
    replaceDoc: 'Replace document',
    deleteDoc: 'Delete document',
    filterBy: 'Filter by',
    submitedDate: 'Submitted date range',
    nomination: 'Nomination',
    docType: 'Document type',
    requiredPayment: 'Required for payment',
    emptyTxt: 'There are no documents to show you',
    deleteDocTitle: 'YOU ARE ABOUT TO DELETE THIS DOCUMENT',
    addFilters: 'Add Filters',
    applyFilter: 'APPLY FILTERS',
    documentFilter: 'DOCUMENT FILTERS',
    errorFileSize: 'The document exceeds the maximum upload size for this site (10mb)',
    noDocuments: 'No document matches the search',
    search: 'Search',
    modalDeleteTitle: 'Document Deleted',
    modalDeleteMessage: 'The document has been deleted successfully',
  },
  pdf: {
    behalf: 'On behalf of',
    buyer: 'Buyer',
    seller: 'Seller',
    contractReference: 'Contract Reference',
    contractName: 'Contract Name',
    contractDate: 'Contract Date',
    totalVolume: 'TOTAL VOLUME',
    packagingType: 'PACKAGING TYPE',
    sugarSpec: 'SUGAR SPEC',
  },
};
