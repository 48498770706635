export default {
  auth: {
    invalidCredentials: 'Seu e-mail ou senha estão incorretos',
    getTokenError: 'Ocorreu um erro ao tentar obter o token do usuário',
    createAccountError: 'Ocorreu um erro ao tentar criar a conta',
    deleteAccountError: 'Ocorreu um erro ao tentar excluir a conta',
    updateProfileError: 'Ocorreu um erro ao tentar atualizar o perfil',
    sendEmailVerificationError: 'Ocorreu um erro ao tentar enviar o e-mail',
    verifyEmailError: 'Ocorreu um erro ao tentar verificar o e-mail',
    ForgotPasswordError: 'Ocorreu um erro ao tentar redefinir a senha',
    changePasswordError: 'Ocorreu um erro ao tentar alterar a senha',
    resetPasswordError: 'Ocorreu um erro ao tentar redefinir a senha',
  },
  freemium: {
    title: 'Junte-se ao piloto INQUARI para obter acesso a:',
    description1: 'Criação de contrato ilimitado',
    description2: 'Integração personalizada para você, sua equipe e colaboradores',
    description3:
      'Três meses adicionais de acesso gratuito quando a plataforma for lançada oficialmente',
    action: 'PARTICIPAR DO PILOTO',
  },
  common: {
    help: 'AJUDA',
    success: 'SUCESSO',
    createAccount: 'CRIAR CONTA',
    create: 'CRIAR',
    login: 'CONECTE-SE',
    signOut: 'SAIR',
    forgotPassword: 'ESQUECEU SUA SENHA?',
    send: 'Enviar',
    done: 'Concluído',
    ok: 'OK',
    firstName: 'Primeiro nome',
    lastName: 'Sobrenome',
    email: 'O email',
    password: 'Senha',
    confirmPassword: 'Confirme sua senha',
    oldPassword: 'Senha Antiga',
    createYourPassword: 'Crie sua senha',
    companyName: 'Nome da empresa',
    continue: 'PROSSEGUIR',
    requiredField: 'Este é um campo obrigatório',
    enterValidEmail: 'Entre com um email válido',
    minPassword: '8 caracteres min.',
    passwordMustMatch: 'A senha deve corresponder',
    ownerEmail: 'Este e-mail deve ser diferente do seu',
    close: 'CERRAR',
    error: 'ERRO',
    reset: 'RESTABLEDER',
    confirm: 'CONFIRME',
    submit: 'ENVIAR',
    cancel: 'CANCELAR',
    assigne: 'ATRIBUIR',
    apply: 'APLIQUE',
    no: 'NÃO',
    yes: 'SIM',
    changePassword: 'MUDAR SENHA',
    saveChanges: 'SALVAR ALTERAÇÕES',
    back: 'Voltar',
    backToPortfolio: 'Voltar ao portfólio',
    edit: 'Editar',
    viewContract: 'VER CONTRATO',
    viewPayment: 'VER INSTRUÇÕES DE PAGAMENTO',
    goHome: 'ACESSE O PORTFOLIO',
    delete: 'Excluir',
    mustBeNumber: 'Este deve ser um número positivo',
    agree: 'CONCORDO',
    dontAgree: 'DISCORDO',
    agreeConfirmationTitle: 'Pronto para confirmar?',
    areYouSure: 'Tem certeza?',
    freemiumErrorTitle: 'ERRO FREEMIUM',
    freemiumErrorMessage: 'Desculpe, você atingiu o limite de contratos para sua conta gratuita.',
    notNow: 'AGORA NÃO',
    goPremium: 'VÁ PREMIUM',
    new: 'Novo',
    forbidden: 'Você não tem permissão suficiente para realizar essa operação',
    notifications: 'NOTIFICAÇÕES',
    today: 'Hoje',
    yesterday: 'Ontem',
    deleteContractTitle: 'VOCÊ ESTÁ PRESTES A EXCLUIR ESTE CONTRATO',
    deleteContractDes1: 'Essa ação não pode ser desfeita',
    deleteContractDes2: 'Você tem certeza que deseja fazer isso?',
    noChangeMyMind: 'Não, mudei de ideia',
    yesDelete: 'Sim, deletar',
    deliverySchedule: 'Cronograma de entrega',
    menu: {
      deliverySchedule: 'Cronograma de entrega',
      contractSummary: 'Resumo do Contrato',
      contractTerms: 'Termos do Contrato',
      paymentInstructions: 'Instruções para pagamento',
      documents: 'Documentos',
      workflow: 'Fluxo de trabalho',
    },
    menuShort: {
      deliverySchedule: 'Entregas',
      contractSummary: 'Resumo',
      contractTerms: 'Contract Terms',
      paymentInstructions: 'Payment Instructions',
      documents: 'Documentos',
      workflow: 'Fluxo',
      more: 'Mais',
    },
    upload: 'Envio',
    rfp: 'Obrigatório',
    document: 'Documento',
    default: 'Por Padrão',
    allBuyers: 'Todos os compradores',
    allSellers: 'Todos os vendedores',
    allProducts: 'Todos os produtos',
    deliveryDateRange: 'Intervalo datas de entrega',
    all: 'Tudo',
    contracts: 'Contratos',
    deliveries: 'Entregas',
    clientSupport: 'Suporte ao cliente',
    havenDefinedDeilvery: 'Você não definiu seu tempo de entrega',
    needDeliveryCadence:
      'Você deve definir uma cadência de entrega antes de gerenciar seu fluxo de trabalho',
    setDeliverySchedule: 'Defina cronograma de entrega',
    privacy: 'POLÍTICA DE PRIVACIDADE',
    terms: 'TERMOS E CONDIÇÕES',
  },
  onboarding: {
    title1: 'Bem-vindo',
    description1:
      'Faça download do aplicativo Google Authenticator para criar e verificar sua conta.',
  },
  createAccount: {
    description: 'Forneça as seguintes informações.',
    description2:
      'Em seguida, verifique se há um link em sua caixa de entrada que verificará seu endereço de e-mail.',
  },
  emailVerification: {
    successMessage: 'Um e-mail foi enviado com sucesso para {{emaill}} para sua verificação.',
    successVerification: 'Obrigado! Seu e-mail foi verificado.',
    title: 'VERIFICAÇÃO DE E-MAIL',
    subtitle: 'SUA CONTA FOI CRIADA COM SUCESSO',
    description: 'Enviamos a você um e-mail para {{email}} com um link para verificar sua conta.',
    sendNewEmail: 'REENVIAR EMAIL',
    refresh: 'VERIFI MEU E-MAIL',
  },
  forgotPassword: {
    title: 'ESQUECEU SUA SENHA?',
    subtitle: 'RESET SUA SENHA',
    description: 'Digite seu e-mail e enviaremos um link para que você possa redefinir sua senha',
    sent: {
      title: 'ENVIADO!',
      description1: 'Um link para redefinir sua senha foi enviado.',
      description2:
        'Em caso de dúvidas ou para relatar um problema, envie um e-mail para help@inquari.com',
    },
  },
  resetPassword: {
    description: 'Por favor insira sua nova senha.',
    success: 'A senha foi atualizada.',
    savePassword: 'SALVAR NOVA SENHA',
  },
  validateToken: {
    title: 'AUTENTICAÇÃO',
    description:
      'Por favor, confirme sua conta digitando o código fornecido pelo autenticador google',
    verifyCode: 'VERIFY CODE',
    resetDescription:
      'Clique aqui se você não tiver acesso ao seu Google Authenticator e quiser redefini-lo por e-mail',
    invalid: 'Token inválido',
    resetSent:
      'Enviamos um e-mail para {{email}} com um link para redefinir o Google Authenticator.',
  },
  setupGoogleAutheticator: {
    title: 'CONFIGURAR AUTENTICADOR',
    'You need to download the': 'Você precisa baixar o',
    'Authenticator App': 'App autenticador',
    'and paste this KEY there': 'e cole esta CHAVE lá',
    'to get your access code': 'para obter o seu código de acesso',
    'or scan the QR Code': 'ou digitalize o código QR',
    'Please verify your identity using the code generated by the':
      'Verifique sua identidade usando o código gerado pelo',
    'Google Authenticator App': 'App Google Authenticator',
    copyKeyMobile: 'COPIAR A CHAVE PARA A PRANCHETA',
    copyKeyDesktop: 'OU CÓPIA DE CHAVE',
    modalTitle: 'FICANDO AUTENTICADO',
    modalInstructions1:
      'Para maximizar a segurança da identidade na plataforma INQUARI, usamos um sistema 2 Factor Authentication que aproveita o aplicativo Google Authenticator.',
    modalInstructions2:
      'Depois de baixar e registrar-se nesse aplicativo, toque no botão Adicionar (+) e copie a chave que demos a você.',
    modalInstructions3:
      'O Google Authenticator mostrará um código numérico que você pode digitar aqui para que possamos confirmar sua conta.',
    modalInstructions4:
      'Você precisará inserir o código do Google Authenticator sempre que precisarmos confirmar sua conta.',
  },
  home: {
    empty: {
      title: '',
      description: '',
      step1Title: '1. Configure seu contrato',
      step1Description:
        'Sabemos que são muitas informações, então você pode concluir isso em etapas ou atribuir alguém de sua equipe para ajudá-lo.',
      step2Title: '',
      step2Description: 'PRECISA DE AJUDA? ',
      step2Link: 'ASSISTA ESSE VIDEO',
      endStepTitle: 'VOCÊ NÃO TEM CONTRATOS',
      endStepDescription: 'CRIE SEU PRIMEIRO CONTRATO',
      welcome: 'Bem-vindo',
      welcomeDescription: 'Configure sua oferta em etapas fáceis',
    },
    filters: 'Filtrar por',
    sortBy: 'Ordenar por',
    // - Delivery status (Behind - On Time - Ahead) - Delivery status (Ahead - On Time - Behind) - Total contract volume - Total nominated volume - Total delivered volume
    deliveryStatusBehind: 'Status de entrega',
    deliveryStatusAhead: 'Status de entrega',
    totalContract: 'Volume total do contrato',
    totalNominated: 'Volume total nomeadoe',
    totalVolume: 'Volume total a ser entregue',
    received: 'Recebido',
    nominated: 'Nomeado',
    pending: 'Pendente',
  },
  filters: {
    title: 'Filtrar por',
    offTakeStatus: 'STATUS DE DESLIGAMENTO',
    currentlyNominated: 'Atualmente nomeado',
    deliveryDateRange: 'Intervalo de datas de entrega',
    buyers: 'Todos os compradores',
    sellers: 'Todos os vendedores',
    products: 'Todos os produtos',
  },
  paymentInstructions: {
    descriptionPayment: `Comunique com segurança as informações da sua conta para (insira o nome do comprador) através deste formulário`,
    title: 'Instruções para pagamento',
    descriptionWithoutSeller:
      'Para habilitar a seção de instruções de pagamento, você precisa incluir informações do vendedor nas informações gerais do contrato',
    description:
      'Essas informações ficarão visíveis apenas para o Comprador e, se você escolher, para o seu Financiador. Você poderá editar essas informações sempre que desejar - no entanto, após a confirmação do Comprador ou do Financiador - qualquer alteração exigirá que eles reconfirmem o contrato.',
    descriptionEditing:
      'Essas informações estarão visíveis apenas para {{buyerName}} e, se você escolher, seu Financiador. Você poderá editar essas informações sempre que desejar - no entanto, após a confirmação de {{buyerName}} ou do Financiador - qualquer alteração exigirá que eles reconfirmem o contrato.',
    descriptionEditingWithFinancier:
      'Esta informação só estará visível para {{buyerName}} e {{financierName}}. Você poderá editar essas informações sempre que desejar - no entanto, após a confirmação de {{buyerName}} ou {{financierName}} - qualquer alteração exigirá que eles reconfirmem seu contrato.',
    title2: 'Cessão de garantia',
    description2: `Se este contrato foi dado como garantia, mantenha todos informados incluindo as informações do financiador abaixo. Isso convidará o financiador a ver e confirmar as instruções de pagamento.`,
    contractReference: 'Nº de referêcia do contrato #',
    buyerEmail: 'Email do Comprador',
    buyerName: 'Nome do Comprador',
    buyerFirstName: 'Primeiro nome',
    buyerLastName: 'Sobrenome',
    accountHolderName: 'Nome da conta',
    bankName: 'NOME DO BANCO, país, cidade, estado',
    routingNumber: 'NÚMERO ROUTING',
    accountNumber: 'NÚMERO DA CONTA',
    financier: 'Atribuído a',
    legalEmail: 'E-MAIL DO REPRESENTANTE LEGAL',
    legalName: 'Nome do Representante Legal',
    legalFirstName: 'NOME DO REPRESENTANTE LEGAL',
    legalLastName: 'Sobrenome',
    beneficiaryTitle: 'INFORMAÇÕES DA CONTA DO BENEFICIÁRIO',
    beneficiaryDescription:
      'Forneça as informações solicitadas abaixo exatamente como aparecem nos registros da instituição financeira em que a conta é mantida.',
    beneficiaryName: 'Nome do beneficiário da conta',
    beneficiaryAddress: 'Endereço físico do beneficiário',
    beneficiaryComments: 'Outros comentários',
    confirmation: {
      title: 'CONFIRMAÇÃO',
      collaborationTitle: 'PEDIDO DE COLABORAÇÃO',
      acknowledgmentTitle: 'RECONHECIMENTO',
      titleBox: 'Instruções para pagamento',
      description:
        'Antes que qualquer alteração possa ser aceita, os termos devem ser confirmados por todas as partes.',
      collaborationSubitle: 'INSTRUÇÃO DE PAGAMENTO DE',
      collaborationContract: 'CONTRATO nº {{contractReference}}',
      to: 'PARA',
      agreements1: ' Confirmo que este contrato não foi dado como garantia',
      agreements2:
        'Gostaria de informar que este contrato de venda foi dado como garantia a {{bankName}} e que também solicitamos o reconhecimento de seu representante legal das instruções de pagamento.',
      updated: 'Instruções de pagamento atualizadas.',
      created: 'Instruções de pagamento criadas.',
    },
    denied: 'Negado',
    confirmed: 'Confirmado',
    pending: 'Pendente',
    approval: 'Aprovação',
    accepted: 'Aceito',
    actionRequired: 'Ação requerida',
    agree: 'CONCORDO',
    dontAgree: 'DISCORDO',
    agreeConfirmationTitle: 'Pronto para confirmar?',
    agreeConfirmationDescription:
      'Ao confirmar, você concorda que todos os pagamentos deste contrato serão feitos para a conta indicada, a menos que essas instruções sejam atualizadas e confirmadas.',
    agreeSuccess: 'Sua atualização foi enviada com sucesso',
    comment: 'COMENTE',
    commentDescription: 'Informe {{sellerName}} por que você discorda.',
    deniedCommentSuccess: 'Obrigado por fornecer seu feedback.',
    lastEdited: 'ÚLTIMA EDITAÇÃO: ',
    requestSent: 'O pedido foi enviado com sucesso',
  },
  profile: {
    title: 'PERFIL',
    successDescription: 'Perfil atualizado com sucesso.',
    viewProfile: 'VER PERFIL',
  },
  contracts: {
    sumary: 'Resumo do Contrato',
    portfolio: 'PORTFOLIO',
    nominated: 'Nomeado',
    behind: 'Atrasado',
    undefined: 'Sem cronograma de entrega',
    addOffTakeSchedule: 'Adicionar cronograma de entrega',
    undefinedDeliver: 'Sem cronograma de entrega',
    active: 'Ativo',
    onTime: 'No prazo',
    ahead: 'Adiantado',
    completed: 'Concluído',
    contractName: 'Nome do contrato',
    contractReference: 'Nº de referêcia do contrato #',
    totalVolume: 'Volume total',
    totalVol: 'VOL TOTAL',
    sugarSpec: 'Especificações do açúcar',
    buyerEmail: 'Email do Comprador',
    buyerEmailLabel:
      'E-mail do comprador (opcional - inclua aqui se desejar iniciar o modo de duas equipes)',
    counterpartPlaceHolder: 'Inclua aqui se desejar iniciar o modo de duas equipes',
    sellerEmail:
      'Email do vendedor (opcional - inclua aqui se desejar iniciar o modo de duas equipes)',
    packagingType: 'Tipo de embalagem',
    createContract: 'CRIAR UM CONTRATO',
    proposeChanges: 'ESBOÇO DE NOVA PROPOSTA',
    manageDeliverySchedule: 'GERENCIAR CRONOGRAMA DE ENTREGA',
    delete: 'Deletar contrato',
    archive: 'Arquivar contrato',
    seeContract: 'VER CONTRATO',
    imcCmmercialExecutive: ' Estou criando o contrato em nome do executivo comercial',
    addCounterpart: 'Eu quero incluir minha contraparte',
    deny: {
      title: 'Adicionar um comentário a {{título}}',
      description: 'Seu comentário será revisado por sua contraparte',
      successMessage: 'Seu comentário foi enviado com sucesso',
      deniedCommentSuccess: 'Obrigado por fornecer seu feedback.',
    },
    reviewChanges: 'Por favor, reveja as alterações propostas pelo {{counterpart}}',
    waitingReview: 'Aguardando aprovação da contraparte',
    waiting:
      'Esta informação está sendo revisada por outras partes. Até que todas as partes tenham confirmado as novas alterações, você não poderá propor novas alterações.',
    changesConfirmed: 'Alterações confirmada por todas as partes em',
    deniedTitle: 'A alteração proposta foi negada.',
    deniedMessage: 'Aguarde até que {{counterpart}} verifique as informações',
    completeContractTerms: 'TERMOS COMPLETOS DO CONTRATO',
    manageWorkflow: 'GERENCIAR FLUXO DE TRABALHO',
    modifyTerms: 'Editar informações gerais',
    generalInformation: {
      title: 'CRIAR TERMOS DE CONTRATO',
      title2: 'TERMOS',
      description: 'Forneça os seguintes termos-chave para criar seu contrato.',
      descriptionEdit: 'Preencha estas informações básicas sobre o seu contrato',
      description2: '',
      iam: 'EU SOU O',
      seller: 'VENDEDOR',
      buyer: 'COMPRADOR',
      sellSide: 'LADO DA COMPRA',
      buySide: 'LADO DA VENDA',
      noSeller: 'SEM VENDEDOR',
      noBuyer: 'SEM COMPRADOR',
      minVolume:
        'São programações off-take que somam {{volumeScheduled}} MT, remova-as antes de fazer qualquer alteração',
      sellerRepresentativeFirstName: 'PRIMEIRO NOME DO VENDEDOR',
      sellerRepresentativeLastName: 'SOBRENOME DO VENDEDOR',
      sellerRepresentativeEmail: 'E-MAIL DO VENDEDOR',
      buyerRepresentativeFirstName: 'NOME DO COMPRADOR',
      buyerRepresentativeLastName: 'SOBRENOME DO COMPRADOR',
      buyerRepresentativeEmail: 'E-MAIL DO COMPRADOR',
      counterpartHelpMessage:
        'Este recurso permite que você convide sua contraparte comercial para que as equipes de compra e venda possam gerenciar seus próprios fluxos de trabalho relacionados ao contrato, bem como colaborar facilmente em tarefas - como nomeação, entrega e uma lista de trabalho de documentos necessários para o pagamento - que concerne ambas as equipes. Ao convidar sua contraparte, os termos do contrato e as programações off-take, as tarefas de nomeação e entrega exigiriam a confirmação de ambas as equipes. Você pode convidar sua contraparte agora ou mais tarde, navegando até os convites de contrato.',
      commercialExecutiveHelpMessage:
        'Considerado a pessoa que realiza a negociação do contrato, e um dos proprietários do contrato',
      success: 'Você criou o seu contrato',
      whatNext: {
        title: 'O que vem a seguir?',
        item1: 'Criar um contrato',
        item2: 'Inclua o resto da sua equipe',
        item3: 'Defina seu cronograma de compra',
        item4: 'Convide colaboradores',
        item5: 'Solicite as instruções de pagamento à sua contraparte',
      },
      goSummary: 'IR PARA O RESUMO DO CONTRATO',
      keepAddingTerms: 'CONTINUE ADICIONANDO OS TERMOSS',
    },
    contractSpecs: {
      title: 'ESPECIFICAÇÕES DE CONTRATO',
      description: '',
    },
    paymentInstructions: {
      title: 'Instruções para pagamento',
      buyerInformation: 'INFORMAÇÕES DE COMPRADORES',
      sellerInformation: 'INFORMAÇÕES DO VENDEDOR',
      sendRequestForPayments: 'ENVIAR PEDIDO DE INSTRUÇÕES DE PAGAMENTO',
      addSellerInfo: 'ADICIONE INFORMAÇÕES DO VENDEDOR',
    },
    offTakeSchedule: {
      title: 'Fluxo de trabalho',
      title2: 'Volume de compras adicionados',
      description:
        'Personalize seu fluxo de trabalho de contrato e monitoramento com volumes alvo e intervalos de datas.',
      description2: 'Todas as alterações precisam ser aprovadas por sua contraparte.',
      volume: 'Volume',
      dateRange: 'Intervalo de datas',
      add: 'SOMAR',
      edit: 'EDITAR',
      sentSuccess: 'Os termos dos horários de folga foram enviados com sucesso',
      volumeExceeded: 'Excede o volume total do contrato ({{totalVolume}}) T',
      minVolume: 'O volume deve ser maior que 0',
    },
    addOtherTerms: {
      addTerms: 'Termos adicionados',
      title: 'OUTROS TERMOS',
      description: 'Adicione todos os seus termos de contrato aqui individualmente',
      add: 'ADICIONAR PRAZO',
      edit: 'EDITAR PRAZO',
      label: 'Rótulo',
      type: 'Tipo',
      descriptionLabel: 'Descrição',
      sentSuccess: 'Os termos do contrato foram enviados com sucesso',
    },
    addCollaborators: {
      title: 'CONVIDAR',
      title2: 'CONVITES',
      description:
        'Convide membros de sua equipe, financiadores e outros agentes para colaborar neste contrato com você.',
      add: 'SOMAR',
      edit: 'EDITAR',
      send: 'ENVIAR CONVITES',
      listTitle: 'Seus colaboradores',
      sentSuccess: 'Os convites foram enviados com sucesso',
      seeRolePermissions: 'Permissões do cargo',
      roles: {
        commercialOwner: 'Proprietário do Contrato',
        contractOwner: 'Proprietário do Contrato',
        operationsManager: 'Gerente de Operações',
        contractAgent: 'Agente do Contrato',
        collaborationAgent: 'Agente de Colaboração ',
        taskAgent: 'Agente de Tarefa',
        exportAgent: 'Agente de Exportação',
        financier: 'Financiador I',
        financier2: 'Financiador II',
        taskCollaborator: 'Colaborador de Tarefas',
        commercialExecutive: 'Comercial Executivo',
      },
      rolePermissions: {
        title: 'Permissões do cargo',
        description: '',
        commercialOwnerDescription: '',
        contractOwnerDescription: '',
        OperationsManagerDescription:
          'Qualquer pessoa responsável pela movimentação da carga para o comércio, seja no comprador ou na equipe do vendedor',
        contractAgentDescription:
          'Qualquer pessoa que desempenhe um papel no fluxo operacional de um contrato que não faça necessariamente parte da equipe do comprador ou do vendedor ou de uma agência de exportação. Pode ser um agente de uma empresa de transporte ',
        CollaborationAgentDescription: '',
        taskAgentDescription: '',
        exportAgentDescription:
          'Qualquer pessoa que desempenhe um papel ativo no processo de exportação de uma negociação',
        financierDescription:
          'Tem maior visibilidade e acesso a mais informações do que um financista II. Os usuários nesta categoria têm acesso ao status do contrato, termos do contrato, instruções de pagamento, cronograma de fluxo de trabalho e capacidade de ver os usuários colaboradores',
        financier2Description:
          'Esta função tem visibilidade mais limitada sobre as informações relativas aos contratos. Comparado a um financista I, eles só podem ver as instruções de pagamento e quaisquer usuários colaboradores para esse contrato ',
        taskCollaboratorDescription: '',
        commercialExecutiveDescription:
          'Considerado a pessoa que realiza a negociação do contrato, e um dos proprietários do contrato',
      },
    },
    details: {
      invite: {
        contractTeam: 'Contrato da equipe',
      },
      contractTerms: 'Termos do Contrato',
      contractTermsAction: '+ADICIONAR OU EDITAR TERMOS',
      contractTeam: 'Contrato da equipe',
      terms: 'TERMOS',
      obligations: 'OBRIGAÇÕES',
      options: 'OPÇÕES',
      workflow: {
        add: 'Adicionar tarefa',
        title: 'Fluxo de trabalho',
        subtitle: 'Configure sua oferta em etapas fáceis',
        first_step_title: '1. Configure sua primeira tarefa',
        first_step_description:
          'Você precisará selecionar o tipo entre Nomeação, entrega e tarefas gerais.',
        offTakeSchedule: {
          title: 'CRONOGRAMA OFF-TAKE',
        },
        offTakeScheduleDetail: {
          title: 'DETALHE DO FLUXO DE TRABALHO',
        },
        empty: {
          endStepTitle: 'VOCÊ NÃO TEM TAREFAS',
          endStepDescription: 'ADICIONE SUA PRIMEIRA TAREFA',
        },
        listTitle: 'Seu cronograma de entrega',
        taskSubtitle: 'Para Entrega: {{date}} / volume Total: {{volume}}MT',
        subtaskSubtitle: 'Para Nomeação: {{date}} - {{volume}}',
        general: 'Geral',
        delivery: 'Entrega',
        nomination: 'Nomeação',
        dataPointRequired: 'REQUISITOS',
        dataPointRequiredDescription: '',
        requirements: 'Requerido para pagamento',
        dataPointDescription: '',
        documentRequired: 'DOCUMENTAÇÃO',
        documentRequiredDescription: '',
        documentDescription: '',
        recipients: 'RECEPTORES',
        generalPage: {
          title: 'DEFINIR TAREFA',
          description: '',
          titleInput: 'Título',
          descriptionInput: 'Descrição',
          completionDate: 'Data de conclusão',
          markDone: 'MARCAR COMO FEITO',
          completingTitle: 'VOCÊ ESTÁ CONFIRMANDO',
          completingDescription: '',
          comments: 'Comentário',
          done: 'Concluído',
        },
        deliveryPage: {
          title: 'DEFINIR TAREFA',
          description: '',
          titleInput: 'Título',
          location: 'Localização',
          pickupLocation: 'Local de coleta',
          countryOrigin: 'País de Origem',
          deliveryLocation: 'Local de entrega',
          completionDate: 'Data de conclusão',
          date: 'Dados',
          tbd: 'TBD',
          markDelivered: 'MARCAR COMO ENTREGUE',
          deliveringTitle: 'VOCÊ ESTÁ CONFIRMANDO A ENTREGA FÍSICA',
          deliveringDescription: '',
          comments: 'Comentário',
          sugarSpec: 'Especificações do açúcar',
          volume: 'Volume',
          delivered: 'Entregue',
          referenceNumber: 'Número de referência',
          optional: '(Opcional)',
          refNumberLabel: 'Ref #',
        },
        nominationPage: {
          title: 'DEFINIR TAREFA',
          description: '',
          pickupLocation: 'Local de coleta',
          countryOrigin: 'País de Origem',
          volume: 'Volume',
          date: 'Dados',
          tbd: 'TBD',
          from: 'De',
          till: 'Até',
          add: 'ADICIONE NOMINAÇÃO',
          timeframe: 'Prazo',
          markNominated: 'Marcar como nomeada',
          nominatingTitle: 'VOCÊ ESTÁ PRESTES A NOMEAR',
          nominatingDescription: '',
          nominated: 'Nomeado',
        },
        invites: {
          title: 'CONVIDAR PARA TAREFA',
          description: '',
          title2:
            'Não está na lista? Sem problemas, digite o e-mail do usuário que deseja atribuir a esta tarefa e clique em adicionar, a seguir clique no botão Atribuir.',
          description2: 'Este usuário só terá acesso às suas tarefas atribuídas',
        },
        sentSuccess: 'A tarefa foi enviada com sucesso',
        requiredForPayment: 'Requerido para pagamento',
        requiredForReview: 'requerido para revisão',
        assignTask: 'CONVIDARA',
        nominationStatusPending:
          'Depois de fazer o upload de todos os requisitos, você poderá definir esta tarefa como concluída',
        addComment: 'ADICIONAR COMENTÁRIO',
      },
    },
    box: {
      type: 'Tipo',
      contractName: 'Nome do contrato',
      totalVolume: 'Volume total',
      contractCode: 'Código do contrato',
      incoTerm: 'INCO termo',
      packagingType: 'Tipo de embalagem',
      sugarSpec: 'Especificações do açúcar',
    },
    contractStatus: 'Estado del contrato',
    today: 'Hoje',
    haventAdded: 'Você não adicionou nenhuma data de entrega',
    addDeliverySchedule: '+ADICIONE sua programação de entrega',
  },
  documents: {
    documentName: 'Nome do documento',
    uploadDate: 'Data do envio',
    nominationVol: 'Nº da nomeação / VOL',
    UploadedBy: 'Enviado por',
    download: 'Baixar',
    replaceDoc: 'Substituir documento',
    deleteDoc: 'Apagar documento',
    filterBy: 'Filtrar por',
    submitedDate: 'Datas de envio',
    nomination: 'Nomeação',
    docType: 'Tipo de documento',
    requiredPayment: 'Requerido para pagamento',
    emptyTxt: 'Não há documentos para mostrar a você',
    deleteDocTitle: 'VOCÊ ESTÁ PRESTES A EXCLUIR ESTE DOCUMENTO',
    addFilters: 'Adicionar Filtros',
    applyFilter: 'APLICAR FILTROS',
    documentFilter: 'FILTROS DE DOCUMENTOS',
    noDocuments: 'Nenhum documento corresponde à pesquisa',
    search: 'Procurar',
  },
};
